import slugify from 'slugify'

export const getLocationSeoPathFromCityState = (
  city: string,
  state: string
) => {
  return `/${slugify(state, { lower: true })}/${slugify(city, {
    lower: true,
  })}/`
}
